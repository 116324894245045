import { ChevronDownIcon } from '@heroicons/react/24/outline'
import { useShowroomKDTMenuQuery } from '../queries/useShowroomKDTMenuQuery'
import { useMemo } from 'react'
import { checkApplyPeriod } from '@/features/klass/utils/checkApplyPeriod'

type IStaticSubMenu = {
  [key: string]: {
    id: string
    title: string
    applyStartedAt: string
    applyEndedAt: string
    isExternalLink: boolean
  }[]
}

type IMenuList = {
  title: string
  href: string | { pathname: string; query: { preNoticeId: string } }
  isApply?: boolean
  isExternalLink?: boolean
}

type ISubMenuType = {
  title: string | null
  menuList: IMenuList[]
}

export type INavList = {
  title: string
  href: string
  icon?: JSX.Element
  subMenu?: ISubMenuType[]
}

const useKDTNavList = () => {
  const { data } = useShowroomKDTMenuQuery()

  const staticSubMenu = useMemo(() => {
    if (data) {
      return {
        ...data,
        심화: [
          ...data['심화'],
          {
            id: 'kdt-aiplusn-1',
            title: 'AI 심화 : 자연어 처리',
            applyStartedAt: '',
            applyEndedAt: '',
            isExternalLink: true,
          },
          {
            id: 'kdt-aiplusi-1',
            title: 'AI 심화 : 이미지 처리',
            applyStartedAt: '',
            applyEndedAt: '',
            isExternalLink: true,
          },
        ],
      } as IStaticSubMenu
    }
  }, [data])

  const navList: INavList[] = [
    {
      title: '부트캠프',
      href: '#',
      icon: <ChevronDownIcon className="w-4 shrink-0 group-open:rotate-180" />,
      subMenu:
        staticSubMenu &&
        Object.keys(staticSubMenu)
          .filter(key => staticSubMenu[key] && staticSubMenu[key].length > 0)
          .map(key => ({
            title: key,
            menuList: staticSubMenu[key].map(
              ({ id, title, applyStartedAt, applyEndedAt, isExternalLink }) => {
                const status = checkApplyPeriod(applyStartedAt, applyEndedAt)
                return {
                  title,
                  href: isExternalLink
                    ? {
                        pathname: `${process.env.NEXT_PUBLIC_HOME_URL}/pre-notice`,
                        query: { preNoticeId: id },
                      }
                    : `/school/${id}`,
                  isApply: status === 'apply',
                  isExternalLink,
                }
              },
            ),
          })),
    },
    {
      title: '취업지원',
      href: '/job-support',
    },
    {
      title: '수강생 스토리',
      href: '#',
      icon: <ChevronDownIcon className="w-4 shrink-0 group-open:rotate-180" />,
      subMenu: [
        {
          title: null,
          menuList: [
            {
              title: '성공 스토리',
              href: 'https://story.likelion.net/ko/categories/191b0433',
              isExternalLink: true,
            },
            {
              title: '우수 프로젝트',
              href: 'https://story.likelion.net/ko/categories/81d76cb7',
              isExternalLink: true,
            },
          ],
        },
      ],
    },
    {
      title: '내일배움카드',
      href: '/nebeca',
    },
  ]

  return {
    navList,
    staticSubMenu,
  }
}

export default useKDTNavList
